/* eslint-disable import/no-unused-modules */
import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { ButtonWithArrow } from '../components/Button';
import Helmet from '../components/Helmet';
import { H1, P } from '../components/Text';
import { PageLayout } from '../shared/layouts';
import withI18n from '../hocs/withI18n';
import notFoundSrc from '../../static/svgs/common/not-found.svg';

export const Head = withI18n(() => {
  return <Helmet pageName="notFound" />;
});

const NotFound = () => {
  const { t, navigate } = useI18next();
  const goHome = () => navigate('/');
  return (
    <PageLayout>
      <PageContainer>
        <NotFoundImg src={notFoundSrc} alt="not-found" />
        <H1>{t('404.title')}</H1>
        <P>{t('404.subtitle')}</P>
        <ButtonWithArrow onClick={goHome}>
          {t('404.redirect_to_home')}
        </ButtonWithArrow>
      </PageContainer>
    </PageLayout>
  );
};

export default NotFound;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
const PageContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2.5rem 0;
  h1,
  p {
    text-align: center;
  }
`;

const NotFoundImg = styled.img`
  margin-bottom: 2rem;
  max-width: 26rem;
  min-width: 12rem;
  width: 25%;
`;
